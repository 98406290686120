<style lang="scss" scoped>
.user-cover {
    background: $blue;
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $white;
}
</style>

<template>
    <div class="user-cover" :style="`width:${width}px;height:${width}px`">{{ name?.[0] }}</div>
</template>

<script>
export default {
    name: "user-cover",
    props: {
        name: String,
        width: {
            type: Number,
            default: 30
        }
    }
}
</script>