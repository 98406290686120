<style lang="scss">
.custom-table {
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;

  .header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-shrink: 0;

    .left {
      display: flex;
      align-items: center;

      .el-input-item {
        display: flex;
        align-items: center;
        flex-wrap: nowrap;
        margin-right: 12px;

        .titles {
          white-space: nowrap;
          font-size: 14px;
        }
      }
    }

    .right {
      flex-shrink: 0;
    }
  }

  .list {
    flex-grow: 1;
    overflow: hidden;
  }

  .pagation {
    flex-shrink: 0;
    padding-top: 24px;
    text-align: right;
  }
}

.stat-he {
  display: flex;
  margin-bottom: 12px;
}
</style>

<template>  
  <div class="custom-table">
    <!-- 统计 -->
    <statistical-all :statistical="statistical"></statistical-all>
    <!-- <div class="statistical-all" v-if="statistical.length !== 0">
            <div class="statistical-item" v-for="item of statistical">
                <div class="stat-he">
                    <div class="name">{{ item.name }}</div>
                <div class="value">￥{{ item.value | thounds }}</div>
                </div>
                <div class="tool-tip" v-if="item.tooltip">
                    <div class="tool-tips" v-for="(v,key) in item.tooltip">{{ key }}：{{ v }}</div>
                </div>
            </div>
        </div> -->

    <!-- 头部 -->
    <div class="header">
      <!-- 过滤 -->
      <div class="left">
        <div class="el-input-item" v-for="item of filters">
          <div class="titles">{{ item.name }}：</div>
          <el-input
            @change="doChange(item)"
            placeholder="请输入"
            :clearable="item.clearable"
            v-model="item.value"
            v-if="item.type == 'input'"
          ></el-input>

          <el-date-picker
            @change="doChange(item)"
            placement="bottom-start"
            placeholder="请选择"
            :clearable="item.clearable"
            v-model="item.value"
            v-if="item.type == 'date'"
          ></el-date-picker>
          <el-radio-group
            @change="doChange(item)"
            placeholder="请选择"
            :clearable="item.clearable"
            v-model="item.value"
            v-if="item.type == 'select'"
          >
            <el-radio v-for="i of item.options" :key="i.name" :label="i.type">{{
              i.name
            }}</el-radio>
          </el-radio-group>
          <!-- <el-select  @change="doChange(item)" placeholder="请选择" :clearable="item.clearable" v-model="item.value"
                        v-if="item.type == 'select'">
                        <el-option v-for="i of item.options" :value="i.type" :key="i.name" :label="i.name"></el-option> -->
          <!-- </el-select> -->
        </div>

        <div class="el-input-item">
          <el-button @click="reset">重置</el-button>
        </div>

        <div class="el-input-item">
          <el-button type="primary" @click="loadDatas(true)">搜索</el-button>
        </div>
      </div>

      <!-- 按钮 -->
      <div class="right">
        <el-button type="primary" @click="creates">创建</el-button>
      </div>
    </div>

    <div class="list">
      <el-table
        :data="list"
        style="width:100%;height:100%"
        @row-click="rowClick"
      >
        <el-table-column
          :label="item.name"
          v-for="item of tableColumns"
          :key="item.name"
        >
          <template slot-scope="scope">
            <!-- 操作区域 -->
            <template v-if="item.isEdit">
              <el-button
                type="danger"
                @click.stop="removes(scope.row)"
                icon="el-icon-delete"
                circle
              ></el-button>
              <el-button
                type="primary"
                @click.stop="edits(scope.row)"
                icon="el-icon-edit"
                circle
              ></el-button>
            </template>

            <!-- pdf文件区域 -->
            <template v-if="item.isPdf">
              <div
                v-if="scope.row[item.url]"
                @click.stop="linkTo(scope.row[item.url])"
              >
                <img
                  style="width:30px;cursor:pointer"
                  src="./assets/Pdf.svg"
                  alt=""
                />
              </div>
              <div v-else style="color:rgb(200,200,200)">暂无文件</div>
            </template>

            <!-- 自定义 -->
            <template v-if="item.isFormat">
              <div>
                {{ item.format(scope.row[item.props]) }}
              </div>
            </template>
            <template v-else>
              <div>
                {{ scope.row[item.props] }}
              </div>
            </template>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <!-- 分页 -->
    <div class="pagation">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page.sync="pageInfo.pageNumber"
        :page-size="pageInfo.pageSize"
        layout="sizes,total, prev, pager, next"
        :total="pageInfo.total"
      >
      </el-pagination>
    </div>

    <!-- 弹框 -->
    <el-drawer
      :visible.sync="showDrawer"
      width="400px"
      :title="drawerType == 'create' ? '创建' : '编辑'"
    >
      <div class="drawer-body">
        <el-form label-position="top" ref="userForm">
          <el-form-item
            prop="name"
            :label="item.name"
            v-for="item of drawers"
            :key="'d' + item.name"
          >
            <el-input
              placeholder="请输入"
              :clearable="item.clearable"
              v-model="item.value"
              v-if="item.type == 'input'"
            ></el-input>

            <el-input
              placeholder="请输入"
              type="textarea"
              :clearable="item.clearable"
              v-model="item.value"
              v-if="item.type == 'textarea'"
            ></el-input>
            <el-date-picker
              placement="bottom-start"
              placeholder="请选择"
              :clearable="item.clearable"
              v-model="item.value"
              v-if="item.type == 'date'"
            ></el-date-picker>
            <el-select
              placeholder="请选择"
              :clearable="item.clearable"
              v-model="item.value"
              v-if="item.type == 'select'"
            >
              <el-option
                v-for="i of item.options"
                :value="i.type"
                :key="i.name"
                :label="i.name"
              ></el-option>
            </el-select>

            <el-date-picker
              v-model="item.value"
              v-if="item.type == 'date-rand'"
              type="daterange"
              range-separator="~"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
            >
            </el-date-picker>

            <el-input-number
              placeholder="请输入"
              :min="item.min"
              v-model="item.value"
              v-if="item.type == 'number'"
            ></el-input-number>
            <custom-file
              :fileTypes="item.fileTypes"
              :clearable="item.clearable"
              v-model="item.value"
              v-if="item.type == 'file'"
            >
            </custom-file>
          </el-form-item>
        </el-form>
      </div>

      <div class="demo-drawer__footer">
        <el-button @click="closeDrawer">取 消</el-button>
        <el-button type="primary" @click="comfrimEdit">确定</el-button>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import { Notification } from "element-ui";

export default {
  name: "custom-table",
  data() {
    return {
      showDrawer: false,
      drawerType: "",
      drawerData: {},
      list: [],
    };
  },

  props: {
    pageInfo: {
      type: Object,
    },
    statistical: {
      type: Array,
      require: false,
      default: () => [],
    },
    tableColumns: {
      type: Array,
      require: true,
      default: () => [],
    },
    filters: {
      type: Array,
      require: false,
      default: () => [],
    },
    drawers: {
      type: Array,
      require: false,
      default: () => [],
    },
    loadData: {
      type: Function,
      default: function() {
        console.log("默认加载数据");
      },
    },
    edit: {
      type: Function,
      default: function() {
        console.log("默认编辑");
      },
    },
    remove: {
      type: Function,
      default: function() {
        console.log("默认删除");
      },
    },
    commitDrawer: {
      type: Function,
      default: function() {},
    },
  },

  methods: {
    rowClick(row) {
      this.$emit("rowClick", JSON.parse(JSON.stringify(row)));
    },
    doChange(item) {
      if (item.doSearch) {
        this.loadDatas(true);
      }
    },
    handleSizeChange(pageSize) {
      this.pageInfo.pageSize = pageSize;
      this.pageInfo.pageNumber = 1;
      this.loadDatas();
    },
    handleCurrentChange(pageNumber) {
      this.pageInfo.pageNumber = pageNumber;
      this.loadDatas();
    },
    linkTo(url) {
      window.open(url);
    },
    async loadDatas(bool = false) {
      if (bool == true) {
        this.resetPageInfo();
      }
      let object = {};
      this.filters.map((e) => {
        if (e.format) {
          object[e.property] = e.format(e.value);
        } else {
          object[e.property] = e.value;
        }
      });
      let data = { ...this.pageInfo, ...object };
      let res = await this.loadData(JSON.parse(JSON.stringify(data)));
      this.pageInfo.total = res.total;
      this.list = res.list;
    },
    edits(row) {
      this.drawerData = JSON.parse(JSON.stringify(row));
      this.drawers.map((e) => {
        if (e.formatDrawer) {
          e.value = e.formatDrawer(this.drawerData[e.property]);
        } else {
          e.value = this.drawerData[e.property];
        }
      });
      this.drawerType = "edit";
      this.showDrawer = true;
    },
    creates() {
      delete this.drawerData["id"];
      this.drawers.map((e) => {
        e.value = e.defaultValue();
      });
      this.showDrawer = true;
      this.drawerType = "create";
    },
    closeDrawer() {
      this.showDrawer = false;
    },
    async comfrimEdit() {
      for (let drawer of this.drawers) {
        if (drawer.require == true) {
          if (drawer.value === "" || drawer.value === null) {
            Notification.error("请选择或输入" + drawer.name);
            return;
          }
        }
      }
      let data = {
        drawerType: this.drawerType,
        id: this.drawerData.id || null,
      };

      //遍历数据
      this.drawers.map((e) => {
        if (e.formatCommit) {
          data[e.property] = e.formatCommit(e.value);
        } else {
          data[e.property] = e.value;
        }
      });
      await this.edit(JSON.parse(JSON.stringify(data)));
      this.$notify.success(
        this.drawerType == "create" ? "创建成功" : "编辑成功" + "成功！"
      );
      this.showDrawer = false;
      if (this.drawerData == "create") {
        this.reset();
      } else {
        this.loadDatas();
      }
    },
    removes(row) {
      this.$confirm(`确定删除吗?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      }).then(async () => {
        await this.remove(row);
        this.$notify.success("删除成功！");
        this.reset();
      });
    },
    reset() {
      this.resetPageInfo();
      this.filters.map((item) => {
        item.value = item.defaultValue();
      });
      this.loadDatas();
    },
    resetPageInfo() {
      // this.pageInfo.pageSize = 10
      this.pageInfo.pageNumber = 1;
    },
  },
  created() {
    this.loadDatas();
  },
};
</script>

<style>
.drawer-body {
  padding-left: 24px;
  padding-right: 24px;
}

.demo-drawer__footer {
  padding-left: 24px;
  padding-right: 24px;
  text-align: right;
}
</style>
