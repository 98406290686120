<style lang="scss" scoped>
.custom-dialog {
    width: 100vw;
    height: 100vh;
    position: fixed;
    left: 0;
    top: 0;
    background: rgba(0, 0, 0, 0.4);

    .dialog-main {
        padding: 12px;
        background: $white;
        margin: 0 auto;
        margin-top: 60px;
        border-radius: 4px;
        overflow: hidden;
        .header{
            display: flex;
            align-items: center;
            justify-content: space-between;
            .title{
                font-size: 16px;
                color: $black;
                font-weight: bold;
            }
            .el-icon-close{
                font-size: 24px;
                cursor: pointer;
            }
            border-bottom: 1px solid $grow-min;
            padding-bottom: 12px;
            margin-bottom: 12px;
        }
    }
}
</style>

<template>
    <div class="custom-dialog">
        <div class="dialog-main" :style="`width:${width}px`">
            <div class="header">
                <div class="title">{{ title }}</div>
                <div class="el-icon-close" @click="closeDialog"></div>
            </div>
            <div class="mains">
                <slot></slot>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'custom-dialog',
    props: {
        width: {
            type: Number,
            default: 800
        },
        title: {
            type: String,
            default: "Dialog"
        }
    },
    methods:{
        closeDialog(){
            this.$emit("closeDialog")
        }
    }
}

</script>