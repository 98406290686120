<style lang="scss" scoped>

.tool-tips{
    text-align: right;
    font-size: 12px;
    line-height: 20px;
    font-weight: bold;
}

.statistical-all {
        width: 100%;
        margin-bottom: 24px;
        flex-shrink: 0;
        display: flex;

        .statistical-item {
            padding: 12px;
            border:1px solid $blue;
            color: $blue;
            width: max-content;
            border-radius: 4px;
            // display: flex;
            margin-right: 12px;

            .name {
                font-size: 14px;
            }

            .value {
                margin-left: 12px;
                font-size: 24px;
                font-weight: bold;
            }
        }

        .statistical-item:nth-child(1){
            color: $white;
            background: $blue;
        }
    }
</style>

<template>

  <div class="statistical-all" v-if="statistical.length !== 0">
            <div class="statistical-item" v-for="item of statistical">
                <div class="stat-he">
                    <div class="name">{{ item.name }}</div>
                <div class="value">￥{{ item.value | thounds }}</div>
                </div>
                <div class="tool-tip" v-if="item.tooltip">
                    <div class="tool-tips" v-for="(v,key) in item.tooltip">{{ key }}：{{ v }}</div>
                </div>
            </div>
        </div>
</template>

<script>

export default{
    name:"statistical-all",
    props:{
        statistical:{
            type:Array,
            default:()=>{
                return []
            }
        }
    },
    created(){
    }
}

</script>