<style lang="scss" scoped>
.menu {
  width: 200px;
  overflow-y: hidden;
  height: 100%;
  background: white;
  flex-shrink: 0;

  .menu-item {
    width: 100%;
    padding-left: 24px;
    cursor: pointer;
    padding-top: 12px;
    padding-bottom: 12px;
    transition: .5s all;
    color: $black;
    font-size: 14px;
    &:hover {
      color: $white;
      background: $blue;
    }

    &#active {
      background: $blue;
      color: $white;
    }
  }


}
</style>

<template>
  <div class="menu">
    <div class="menu-item" v-for="item of list" :id="item.name == $route.name ? 'active' : ''"
      @click="linkTo(item.name)" :key="item.name">{{ item.meta.name }}</div>
  </div>
</template>


<script>
import HomeRoutes from "@/router/routes/home"
export default {
  name: "custom-menu",
  data() {
    return {
      list: HomeRoutes
    }
  },
  methods: {
    linkTo(name) {
      this.$router.replace({ name })
    }
  }
}

</script>
