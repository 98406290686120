import Vue from "vue";
import App from "./App";
import router from "./router";
import store from "./store";
import "@/utils/index";
import ElementUI from "element-ui";
import "./design/theme/index.css"
import "./components";
import "./design/index.scss";
import "./directives"
import "./filters"

Vue.use(ElementUI);
Vue.config.productionTip = false;
Vue.config.devtools=true
new Vue({
  el: "#app",
  router,
  store,
  render: (h) => h(App),
});
