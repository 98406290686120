import axios from "@/lib/axios";


export const uploadFile=async (file)=>{
    let form=new FormData()
    form.append("file",file)
    let {data}=await axios.request({
        url:"file/uploading",
        data:form,
        method:"post"
    })

    return "https://"+data.url
}

