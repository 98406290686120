/**
 * @description 路由信息
 */

import HomeRoutes from "./home"

const routes=[
    {
        name:"login",
        path:"/login",
        component:()=> import("@/views/login")
    },
    
    {
        name: "index",
        path: "/",
        component: () => import("@/views/index"),
        redirect:"/home",
        children:HomeRoutes
    }
]

export default routes
