<style lang="scss" scoped>
.contract-main {
    width: 100vw;
    height: 100vh;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 2;
    background: white;
    display: flex;
    flex-direction: column;

    .contract-main-title {
        height: 60px;
        flex-shrink: 0;
        display: flex;
        align-items: center;
        border-bottom: 1px solid $grow-min;
        font-size: 18px;
        font-weight: bold;
        padding: 24px;

    }

    .contract-footer {
        height: 60px;
        flex-shrink: 0;
        display: flex;
        align-items: center;
        border-top: 1px solid $grow-min;
        font-size: 18px;
        font-weight: bold;
        padding: 24px;
        justify-content: center;
    }

    .main {
        flex-grow: 1;
        overflow-y: hidden;
        padding: 24px;
        padding-top: 0;
        display: flex;
        flex-direction: column;
    }

    .contract-main-count {
        width: 100%;
        padding-top: 24px;
        padding-bottom: 24px;
        padding-left: 24px;
        display: flex;
        align-items: center;
        border-bottom: 1px solid $grow-min;

        .contract-main-count-item {
            width: 180px;
            padding: 12px;
            background: $blue;
            margin-right: 12px;
            border-radius: 4px;

            .title {
                color: $white;
                margin-bottom: 12px;
                font-weight: bold;

            }

            .value {
                text-align: right;
                font-size: 18px;
                color: $white;
                font-weight: bold;
            }
        }

        .contract-main-count-item-1 {
            width: 180px;
            border-radius: 4px;
            padding: 12px;
            border: 1px solid $blue;
            margin-right: 24px;

            .title {
                color: $blue;
                margin-bottom: 12px;
                font-weight: bold;
            }

            .value {
                text-align: right;
                font-size: 18px;
                color: $blue;
                font-weight: bold;
            }
        }
    }

}
</style>

<template>
    <div class="contract-main">

        <!-- 标题 -->
        <div class="contract-main-title">项目详情</div>


        <!-- 统计 -->
        <div class="contract-main-count">
            <div class="contract-main-count-item">
                <div class="title">应收</div>
                <div class="value">￥{{ 20000000 }}</div>
            </div>


            <div class="contract-main-count-item">
                <div class="title">已收</div>
                <div class="value">￥{{ 20000000 }}</div>
            </div>

            <div class="contract-main-count-item">
                <div class="title">未收</div>
                <div class="value">￥{{ 20000000 }}</div>
            </div>


            <div class="contract-main-count-item-1">
                <div class="title">应付</div>
                <div class="value">￥{{ 20000000 }}</div>
            </div>

            <div class="contract-main-count-item-1">
                <div class="title">已付</div>
                <div class="value">￥{{ 20000000 }}</div>
            </div>

            <div class="contract-main-count-item-1">
                <div class="title">未付</div>
                <div class="value">￥{{ 20000000 }}</div>
            </div>
        </div>

        <div class="main">
            

        </div>

        <!-- 底部工作栏 -->
        <div class="contract-footer" @click="cancel">
            <el-button @click="cancel">返回</el-button>
            <el-button type="primary" @click="save">保存</el-button>
        </div>
    </div>
</template>

<script>
import bus from "../../lib/bus";
export default {
    name: "contract-main",
    props: {
        contract: {
            type: Object
        }
    },
    components: {
    },
    methods: {
        cancel() {
            this.$parent.showContract = false
        },
        async save() {
            bus.$emit("saveContract")
        }
    }
}
</script>