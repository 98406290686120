/**
 * @description axios配置管理
 */

import axios from "axios"
import router from "@/router"
import { Notification } from "element-ui"
import { NETWORD_SUCCESS_CODE, NETWORK_UN_LOGIN_CODE } from "../../constant/system"
import { getToken } from "../../utils"

let instance = axios.create({
    // baseURL: "https://wealth-ad.linktwins.com",
    // baseURL: "https://wealth-ad.linktwins.com/api/v1",
    baseURL: "https://wapi.linktwins.com/api/v1",
    timeout: 100000,
    headers: {
        get: {
            'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8'
        },
        post: {
            'Content-Type': 'application/x-www-form-urlencoded'
        }
    }
})

/**
 * @author
 */
instance.interceptors.request.use(config => {
    let token =getToken()
    if (token) {
        config.headers.token = token;

    }
    return config
})

instance.interceptors.response.use(data => {

    //成功返回信息
    if (data.data.code === NETWORD_SUCCESS_CODE) {
        return data.data
    } else {
        //如果未登录
        Notification.error(data.data.errorMsg||data.data.errorMessage)
        if (data.data.code == 51 || data.data.code == 52) {
            router.replace({
                name: "login"
            })
        }
        throw new Error(data.data.errorMsg||data.data.errorMessage)
    }

})

export default instance