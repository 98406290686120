<style lang="scss">
.custom-file {
    width: 100px;
    height: 100px;
    border: 1px solid $blue;
    border-radius: 4px;

    .not-add {
        cursor: pointer;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        color: $blue;
        font-size: 50px;
    }
    .has-add{
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        .close{
            color: $red;
            position: absolute;
            right:10px;
            top:10px;
            cursor: pointer;
        }
    }
    .file{
    }
}
</style>

<template>
    <div class="custom-file">
        <input type="file" ref="file" @change="changeFile" :accept="fileTypes" v-show=false id="file">
        <div class="has-add" v-if="modelValue">
            <img style="width:50px" @click="toFile" src="./assets/Pdf.svg" alt="">
            <div class="close el-icon-delete-solid" @click="clearFile"></div>
        </div>
        <div class="not-add" v-else @click="add">
            <i class="el-icon-plus"></i>
        </div>
    </div>
</template>

<script>

import { uploadFile } from "@/api"
export default {
    name: "custom-file",
    props: {
        clearable: Boolean,
        fileTypes: String,
        modelValue: {
            type: String,
        },
    },
    model: {
        prop: 'modelValue',
        // 随便命名事件，对应下面$emit即可
        event: 'updaetModelValue'
    },
    methods: {
        toFile(){
            window.open(this.modelValue)
        }, 
        add() {
            this.$refs['file'].click()
        },
        async changeFile(e) {
            let file = e.target.files[0]
            // let fileUrl = e.target.files[0]
            let url = await uploadFile(file)
            this.$emit("updaetModelValue",url)
            e.target.value = null
        },
        async clearFile() {
            this.$emit("updaetModelValue",'')
        }
    }
}
</script>