<style lang="scss" scoped>
.custom-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-shrink: 0;

    width: 100%;
    height: 60px;
    background: $white;

    padding: 24px;
    border-bottom: 1px solid rgb(220,220,220);

    .title {
        color: black;
        font-size: 18px;
        font-weight: bold;
    }

}
</style>

<template>
    <div class="custom-header">
        <div class="title">财务管理平台</div>
        <el-dropdown>
            <user-cover :width="40" :name="$store.state.user?.username"></user-cover>
            <el-dropdown-menu slot="dropdown">
                <el-dropdown-item @click.native="exitLogin">退出登录</el-dropdown-item>
            </el-dropdown-menu>
        </el-dropdown>
    </div>
</template>

<script>

export default {
    name: "custom-header",
    data() {
        return {

        }
    },
    methods: {
        exitLogin() {
            this.$store.dispatch("exitLogin")
        }
    }
}
</script>