/**
 * @description vue状态管理
 */

import store from "vuex"
import Vue from "vue"
import router from "../router"
import { Notification } from "element-ui"
import { getToken, setToken } from "../utils"
import { getUserMessage, login } from "../api/user"

Vue.use(store)

export default new store.Store({
    state: {
        user:{
            username:""
        }
    },
    mutations: {
        setUserInfo(state,user){
            state.user=user
        }
    },
    actions: {
        /**
         * @description 测试登录
         */
        async getUserMessage(store){
            let token=getToken()
            if(!token){
                router.push({
                    name:"login"
                })
            }else{
                try{
                    let res=await getUserMessage()
                    store.commit('setUserInfo',res.data)
                }catch(error){
                    console.log(error);
                }
            }
        },
        /**
         * @description 登录操作
         */
        async login(store,{username,password}){

            try{
                let res=await login({username,password})
                setToken(res.data.token)
                store.commit('setUserInfo',res.data.userVo)
                router.replace({
                    name:"index"
                })
                Notification.success({
                    duration:500,
                    title:"登陆成功！"
                })
            }catch(error){

            }

           
        },
        /**
         * @description 退出登录
         */
        async exitLogin(){
            setToken('')
            router.replace({
                name:"login"
            })
            Notification.success({
                duration:500,
                title:"退出登陆成功！"
            })
        }
    }
})
