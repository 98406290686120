import axios from "@/lib/axios";

export const getUserMessage = () => {
    return axios.request({
        url: "login/getUserMessage",
        // method:"post"
    })
}

export const login = ({
    username, password
}) => {
    return axios.request({
        url: "login/login_auth",
        method:"post",
        data:{
            username,password
        }
    })
}
