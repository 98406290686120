import Vue from "vue"
Vue.filter("thounds",(thounds)=>{
    thounds+=''
    return thounds
    // return thounds.replace(/\d/g,function(){
        // if((thounds.length-arguments[1]-1)%4===0&&thounds.length-1!==arguments[1]){
        //     return arguments[0]+","
        // }else{
        //     return arguments[0]
        // }})
})