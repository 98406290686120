/**
 * @description 首页路由
 */

const HomeRoutes= [
    {
        name: "home",
        path: "/home",
        component: () => import("@/views/index/views/home"),
        meta:{
            name:"首页"
        }
    },
    {
        name:"project",
        path:"/project",
        component:()=>import("@/views/index/views/project"),
        meta:{
            name:"业务项目"
        }
    },
    {
        name:"wiki",
        path:"/wiki",
        component:()=>import("@/views/index/views/wiki"),
        meta:{
            name:"知识产权"
        }
    },
    {
        name:"offical",
        path:"/offical",
        component:()=>import("@/views/index/views/offical"),
        meta:{
            name:"办公费"
        }
    },
    {
        name: "fix-assets",
        path: "/fix-assets",
        component: () => import("@/views/index/views/fix-assets"),
        meta:{
            name:"固定资产"
        }
    },
    {
        name: "welfare",
        path: "/welfare",
        component: () => import("@/views/index/views/welfare"),
        meta:{
            name:"福利费"
        }
    },
    {
        name: "manmade",
        path: "/manmade",
        component: () => import("@/views/index/views/manmade"),
        meta:{
            name:"人工"
        }
    },
    {
        name: "taxation",
        path: "/taxation",
        component: () => import("@/views/index/views/taxation"),
        meta:{
            name:"税费"
        }
    },
    {
        name: "customer",
        path: "/customer",
        component: () => import("@/views/index/views/customer"),
        meta:{
            name:"客户管理"
        }
    },
    
]

export default HomeRoutes